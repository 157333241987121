import React, { useEffect, useRef, useState } from 'react'
import './Collections.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../reducers/appSlice'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import { selectCurrentCollectionId, setCurrentCollectionId } from '../../reducers/collectionSlice'

const Collections = () => {

    const [isFullscreen, setIsFullscreen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const data = useSelector(selectData)
    const currentCollectionId = useSelector(selectCurrentCollectionId)

    const dispatch = useDispatch()

    useEffect(() => {
        if (data && data.length > 0) {
            dispatch(setCurrentCollectionId(data[0].id))
        }
    },[data, dispatch])

    const imageGalleryRef = useRef()

    const onImageClick = () => {
        const index = imageGalleryRef.current.getCurrentIndex()
        setCurrentIndex(index)
        imageGalleryRef.current.toggleFullScreen()
    }

  return (
    <div className='collections'>
        {data && data.length > 0 ? (
            data.map(col => {
                if (col.id === currentCollectionId) {
                    return (
                        <div className='collection' key={col.id}>
                            <div className='collection-header'>
                                <span>
                                    <div></div>
                                </span>
                                <h3>{col.name}</h3>
                                <span>
                                    <div></div>
                                </span>
                            </div>
                            <div className='image-gallery-container'>
                                <ImageGallery 
                                    items={col.photos ? col.photos.map(photo => {
                                        return (
                                            {
                                                ...photo,
                                                description: `${!isFullscreen ? (
                                                    `${photo.camera_settings.lens} | F${photo.camera_settings.f_stop} | ${photo.camera_settings.shutter_speed} | ${photo.camera_settings.exposure_comp}X | ${photo.camera_settings.shooting_mode}`
                                                ) : ''}`,
                                                bulletClass: 'image-gallery-bullets-extra-class',
                                            }
                                        )
                                    }) : []}
                                    showNav={false}
                                    showThumbnails={false}
                                    showFullscreenButton={false}
                                    useBrowserFullscreen={false}
                                    showPlayButton={false}
                                    infinite={false}
                                    additionalClass='image-gallery-extra-class'
                                    showBullets={true}
                                    onScreenChange={() => {
                                        setIsFullscreen(!isFullscreen)
                                    }}
                                    ref={imageGalleryRef}
                                    onClick={onImageClick}
                                    startIndex={currentIndex}
                                />
                            </div>
                            <p>{col.description}</p>
                            {/* <h6>.</h6> */}
                        </div>
                    )
                } else {
                    return ''
                }
            })
        ) : ''}
    </div>
  )
}

export default Collections