import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: {}
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
    }
})

export const { setData } = appSlice.actions

export const selectData = (state) => state.app.data

export default appSlice.reducer