import React, { useRef, useState } from 'react'
import './AddPhoto.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddPhotoInputError, selectUploadProgress, setAddPhotoInputError, setToggleAddPhoto } from '../../reducers/photoSlice'
import { v4 as uuidv4 } from 'uuid'
import { FaRegWindowClose } from 'react-icons/fa'

const AddPhoto = ({
  handleUploadImage,
}) => {

  const uploadProgress = useSelector(selectUploadProgress)
  const addInputError = useSelector(selectAddPhotoInputError)

  const hiddenFileInput = useRef(null)

  const [imageUpload, setImageUpload] = useState(null)

  const [currentFormPage, setCurrentFormPage] = useState(1)

  const [subject, setSubject] = useState('')
  const [camera, setCamera] = useState('')
  const [dateShot, setDateShot] = useState('')
  const [filmStock, setFilmStock] = useState('')
  

  const [flash, setFlash] = useState(false)
  const [lens, setLens] = useState('')
  const [fStop, setFStop] = useState(2)
  const [shutterSpeed, setShutterSpeed] = useState('1/2000')
  const [exposureComp, setExposureComp] = useState(1)
  const [shootingMode, setShootingMode] = useState('')

  const dispatch = useDispatch()

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div className='add-photo'>
        <h2>Add Photo</h2>
      
        <form onSubmit={(e) => {
            e.preventDefault()
            if (subject !== '') {
              let id = uuidv4()      
              handleUploadImage(imageUpload, {
                id: id,
                subject: subject,
                camera: camera,
                date_shot: dateShot,
                film_stock: filmStock,
                camera_settings: {
                    flash: flash,
                    lens: lens,
                    f_stop: fStop,
                    shutter_speed: shutterSpeed,
                    exposure_comp: exposureComp,
                    shooting_mode: shootingMode
                }
              })       
              // handleMessage('Photo added!')
              // dispatch(addPhotoToRoll({
              //     userId: userId,
              //     cameraId: currentCameraId,
              //     rollId: currentRollId,
              //     photoData: {
              //         id: id,
              //         subject: subject,
              //         shooting_mode: shootingMode,
              //         lens: lens,
              //         shutter_speed: shutterSpeed,
              //         f_stop: fStop,
              //         exposure_comp: exposureComp,
              //         date_created: createDateString(),
              //         url: `https://firebasestorage.googleapis.com/v0/b/film-catalog-b8470.appspot.com/o/default%2Fdefault.JPEG?alt=media&token=7de6b132-7b5f-455c-aa63-1bc320d6df48`
              //     }
              // }))
              // dispatch(setAddPhotoActive(false))
            } 
        }}>
            <FaRegWindowClose onClick={() => dispatch(setToggleAddPhoto(false))}/>
            {/* <select 
                onChange={(e) => {
                    const temp = JSON.parse(e.target.value)
                    dispatch(setCurrentCameraId(temp.id))
                    if (temp.film_rolls.length > 0) {
                        dispatch(setCurrentRollId(temp.film_rolls[0].id))
                    }
                }
                }
                value={defaultCameraValue}
            >
                {data ? (
                    data.map(camera => {
                        return (
                        <option 
                            key={camera.id} 
                            value={JSON.stringify(camera)} 
                            // selected={camera.id === currentCameraId ? 'selected' : ''}
                        >
                            {formatRollName ? formatRollName(camera, 'user') :''}
                        </option>
                        )
                    })
                ) : ''}
            </select>

            <select 
                onChange={(e) => {
                        const temp = JSON.parse(e.target.value)
                        dispatch(setCurrentRollId(temp.id))
                    }
                }
                value={defaultRollValue}
            >
                {localCameraData.film_rolls ? (
                    localCameraData.film_rolls.map(roll => {
                        return (
                        <option 
                            key={roll.id} 
                            value={JSON.stringify(roll)}
                        >
                            {formatRollName ? formatRollName(roll, 'user') : ''}
                        </option>
                        )
                    })
                ) : ''}
            </select> */}

            {currentFormPage === 1 ? (
                <div className='add-photo-page-1'>
                    <label htmlFor='subject'>Subject:</label>
                    <input 
                        type='text'
                        value={subject} 
                        name='subject' 
                        id='subject' 
                        className={`subject-input ${addInputError ? 'error' : ''}`}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />

                    <label htmlFor='camera'>Camera:</label>
                    <input 
                        type='text'
                        value={camera}
                        name='camera'
                        id='camera'
                        className={`camera-input ${addInputError ? 'error' : ''}`}
                        onChange={(e) => setCamera(e.target.value)}
                        required
                    />

                    <label htmlFor='date-shot'>Date Shot:</label>
                    <input 
                        type='date'
                        value={dateShot}
                        name='date-shot'
                        id='date-shot'
                        className={`date-shot-input ${addInputError ? 'error' : ''}`}
                        onChange={(e) => setDateShot(e.target.value)}
                        required
                    />

                    <label htmlFor='film-stock'>Film Stock:</label>
                    <input 
                        type='text'
                        value={filmStock}
                        name='film-stock'
                        id='film-stock'
                        className={`film-stock-input ${addInputError ? 'error' : ''}`}
                        onChange={(e) => setFilmStock(e.target.value)}
                        required
                    />

                    <button 
                        type='button'
                        onClick={() => {
                            if (subject !== '' && camera !== '' && filmStock !== '') {
                                setCurrentFormPage(2)
                                dispatch(setAddPhotoInputError(false))
                            } else {
                                dispatch(setAddPhotoInputError(true))
                            }
                        }}
                        className='mobile-button-styler'
                    >Next</button>
                </div>

            ) : currentFormPage === 2  ? (
                <div className='add-photo-page-2'>
                    {uploadProgress === 100 ? (
                        <p>Upload Complete!</p>
                    ) : (
                        <div>
                        <div 
                            className='upload-progress'
                            style={{
                                width: `${uploadProgress}%`,
                                height: '0.5rem',
                                backgroundColor: 'green',
                                position: 'relative',
                                zIndex: '10',
                            }}
                        >
                        </div>
                        {imageUpload !== null ? (
                            <p>File: {imageUpload.name}</p>
                        ) : ''}
                        </div>

                    )}
                    <div className='image-upload-container'>
                        <input 
                            type="file"
                            onChange={(e) => {
                                setImageUpload(e.target.files[0])
                            }}
                            ref={hiddenFileInput}
                            style={{display: 'none'}}
                        />
                        <button 
                            onClick={handleClick}
                            type='button'
                            className='mobile-button-styler'
                        >Select Upload</button>
                        {/* <button 
                            onClick={() => {
                                handleUploadImage(imageUpload)
                                setImageUpload(null)
                                // dispatch(setUploadProgress(0))
                            }}
                            type='button'
                            className='mobile-button-styler'
                        >Upload Image</button> */}
                    </div>
                    <div className='flash-container'>
                        <input 
                            type='checkbox'
                            value={flash}
                            name='flash'
                            id='flash'
                            className='flash-input'
                            onChange={() => {
                                setFlash(!flash)
                                if (!subject.includes(' w/ flash')) {
                                    const s = subject + ' w/ flash'
                                    setSubject(s)
                                } else {
                                    const s = subject.replace(' w/ flash', '')
                                    setSubject(s)
                                }
                            }}
                        />
                        <label htmlFor='flash'>Flash?</label>
                    </div>

                    <div className='fieldset-container'>
                        <label htmlFor='shooting-mode'>Shooting Mode:</label>
                        <input 
                        type='text'
                        name='shooting-mode'
                        id='shooting-mode'
                        value={shootingMode}
                        onChange={(e) => {
                            setShootingMode(e.target.value)
                        }}
                        />
                        <label htmlFor='lens'>Lens:</label>
                        <input 
                        type='text'
                        name='lens'
                        id='lens'
                        value={lens}
                        onChange={(e) => {
                            setLens(e.target.value)
                        }}
                        />
                        {/* <fieldset 
                            onChange={(e) => {
                                let word = e.target.value
                                const s = word.charAt(0).toUpperCase() + word.slice(1)
                                setLens(s)
                            }}
                            name='lens'
                        >
                            <legend>Lens:</legend>
                            <div className='select-lens'>
                                {localCameraData.lenses ? (
                                    localCameraData.lenses.map(lensItem => {
                                        return (
                                            <span key={lensItem}>
                                                <input type="radio" name="lens" value={lensItem} required/>
                                                <label htmlFor={lensItem}>{lensItem}</label>
                                            </span>
                                        )
                                    })

                                ) : ''}
                            </div>

                        </fieldset> */}
                    </div>
                    
                    <label htmlFor='shutter-speed'>Shutter Speed:</label>
                    <select name='shutter-speed' id='shutter-speed' onChange={(e) => setShutterSpeed(e.target.value)} value={shutterSpeed}>
                        <option value='1/2000' >1/2000</option>
                        <option value='1/1000'>1/1000</option>
                        <option value='1/500' >1/500</option>
                        <option value='1/250' >1/250</option>
                        <option value='1/125' >1/125</option>
                        <option value='1/60' >1/60</option>
                        <option value='1/30' >1/30</option>
                        <option value='1/15' >1/15</option>
                        <option value='1/8' >1/8</option>
                        <option value='1/4' >1/4</option>
                        <option value='1/2' >1/2</option>
                        <option value='1/1' >1/1</option>
                        <option value='2' >2</option>
                        <option value='4' >4</option>
                    </select>

                    <label htmlFor='f-stop'>F-Stop: {fStop}</label>
                    <input 
                        type='range'
                        value={fStop} 
                        name='f-stop' 
                        id='f-stop' 
                        list='f-stop-markers'
                        min='1'
                        max='22'
                        step='0.1'
                        onChange={(e) => setFStop(e.target.value)}
                        required
                    />
                    <datalist id='f-stop-markers'>
                        <option value='1.2' ></option>
                        <option value='2' ></option>
                        <option value='2.8'></option>
                        <option value='4' ></option>
                        <option value='5.6' ></option>
                        <option value='8' ></option>
                        <option value='11' ></option>
                        <option value='16' ></option>
                        <option value='22' ></option>
                    </datalist>

                    <label htmlFor='exposure-comp'>Exposure Comp: {exposureComp}</label>     
                    <input 
                        type='range'
                        value={exposureComp}
                        name='exposure-comp'
                        id='exposure-comp'
                        list='exposure-comp-markers'
                        min='-4'
                        max='4'
                        step='0.25'
                        onChange={(e) => setExposureComp(e.target.value)}
                        required
                    />
                    <datalist id='exposure-comp-markers'>
                        <option value='-4'></option>
                        <option value='-2'></option>
                        <option value='1'></option>
                        <option value='2'></option>
                        <option value='4'></option>
                    </datalist>
                    
                    <button
                        type='button'
                        onClick={() => setCurrentFormPage(1)}
                        className='mobile-button-styler'
                    >Back</button>
                </div>
            ) : '' }
            <input 
                type='submit'
                value='Add'
                className='submit-add-photo'
            />     
        </form>
    </div>
  )
}

export default AddPhoto