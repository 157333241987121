import React, { useState } from 'react'
import './AddCollection.css'
import { v4 as uuidv4 } from 'uuid'


const AddCollection = ({
    handleAddCollection
}) => {

    const [collectionName, setCollectionName] = useState('')
    const [collectionDescription, setCollectionDescription] = useState('')

  return (
    <div className='add-collection'>
        <form onSubmit={(e) => {
            e.preventDefault()
            handleAddCollection({
                name: collectionName,
                description: collectionDescription,
                id: uuidv4(),
                photos: []
            })
        }}>
            <label htmlFor='collection-name'>Collection Name:</label>
            <input 
                type='text'
                name='collection-name'
                id='collection-name'
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                required
            />
            <label htmlFor='collection-description'>Description</label>
            <textarea 
                type='textarea'
                rows='10'
                name='collection-description'
                id='collection-description'
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
            />

            <input 
                type='submit'
                value='Add'
            />
        </form>
    </div>
  )
}

export default AddCollection