import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userData: [],
    toggleLoginError: false,
    loginError: {},
    isLoggedIn: false
}

export const userSlice = createSlice( {
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        setToggleLoginError: (state, action) => {
            state.toggleLoginError = action.payload
        }, 
        setLoginError: (state, action) => {
            state.loginError = action.payload
        }, setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        }
    }
})

export const { 
    setUserData, 
    setToggleLoginError, 
    setLoginError, 
    setIsLoggedIn
} = userSlice.actions

export const selectUserData = (state) => state.user.userData
export const selectToggleLoginError = (state) => state.user.toggleLoginError
export const selectLoginError = (state) => state.user.loginError
export const selectIsLoggedIn = (state) => state.user.isLoggedIn

export default userSlice.reducer