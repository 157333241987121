import React, { useState } from 'react'
import './Header.css'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCollectionId, setToggleCollectionDropdown } from '../../reducers/collectionSlice'
import { selectData } from '../../reducers/appSlice'

const Header = ({
  handleNavigate,
}) => {

  const data = useSelector(selectData)

  const dispatch = useDispatch()

  const [menuControlActive, setMenuControlActive] = useState(false)

  return (
    <header>
      <div className={`menu-control ${menuControlActive ? 'active' : ''}`}
        onClick={() => {
          setMenuControlActive(!menuControlActive)
        }}  
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div>
        <div className={`side-menu-container ${menuControlActive ? 'active' : ''}`}
          onClick={() => setMenuControlActive(false)}
        ></div>
        <div className={`side-menu ${menuControlActive ? 'active' : ''}`}>
          <div className='side-menu-header'>
            <h2>collections</h2>
          </div>
          <ul>
              {data && data.length > 0 ? (
                  data.map(col => {
                      return (
                          <li 
                              key={col.id}
                              onClick={() => {
                                  dispatch(setCurrentCollectionId(col.id))
                                  dispatch(setToggleCollectionDropdown(false))
                                  setMenuControlActive(false)
                              }}
                          >
                              <p>{col.name}</p>
                          </li>
                      )
                  })
              ) : ''}
          </ul>
        </div>
      </div>
      <h1 onClick={() => handleNavigate('/')}>Graham Jantz</h1>
    </header>
  )
}

export default Header