import React, { useEffect, useRef } from 'react'
import './Admin.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../reducers/appSlice'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import { FaPlus } from 'react-icons/fa'
import { selectCurrentCollectionId, setCurrentCollectionId, setToggleAddCollection, setToggleEditCollection } from '../../reducers/collectionSlice'
import { selectCurrentPhotoId, setCurrentPhotoId, setToggleAddPhoto, setToggleEditPhoto } from '../../reducers/photoSlice'


const Admin = () => {

  const data = useSelector(selectData)
  const currentCollectionId = useSelector(selectCurrentCollectionId)
  const currentPhotoId = useSelector(selectCurrentPhotoId)

  const dispatch = useDispatch()

    const imageGalleryRef = useRef(null)

    const onImageClick = (event) => {
        console.debug(
            "clicked on image", 
            event.target,
            "at index",
            imageGalleryRef.current.getCurrentIndex()
        )
        imageGalleryRef.current.toggleFullScreen()
    }

    useEffect(() => {
        if (data && data.length > 0) {
            data.forEach(col => {
                if (col.id === currentCollectionId) {
                    if (col.photos.length > 0) {
                        col.photos.forEach(photo => {
                            if (currentPhotoId === '') {
                                dispatch(setCurrentPhotoId(col.photos[0].id))
                            }
                        })
                    }
                }
            })
        }
    },[data, currentCollectionId, dispatch, currentPhotoId])

  return (
    <div className='admin'>
      <div>
        <FaPlus 
            onClick={() => dispatch(setToggleAddCollection(true))}
        />
        <h2>collections</h2>
      </div>
        {data && data.length > 0 ? (
            data.map(col => {
                // console.log(col)
                return (
                    <div className='collection' key={col.id}>
                        <div className='collection-header'>
                            <span>
                                <div></div>
                            </span>
                            <h3>{col.name}</h3>
                            <span>
                                <div></div>
                            </span>
                        </div>
                        <button
                            className='mobile-button-styler'
                            onClick={() => {
                                dispatch(setToggleEditCollection(true))
                                dispatch(setCurrentCollectionId(col.id))
                            }}
                        >Edit Collection</button>
                        <div className='image-gallery-container'>
                            <ImageGallery 
                                items={col.photos ? col.photos.map(photo => {
                                    return (
                                        {
                                            ...photo,
                                            description: `${photo.camera_settings.lens} | F${photo.camera_settings.f_stop} | ${photo.camera_settings.shutter_speed} | ${photo.camera_settings.exposure_comp}X | ${photo.camera_settings.shooting_mode}`,
                                            
                                        }
                                    )
                                }) : []}
                                showNav={false}
                                showThumbnails={false}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                infinite={false}
                                additionalClass='image-gallery-extra-class'
                                onSlide={(currentIndex) => {
                                    dispatch(setCurrentPhotoId(col.photos[currentIndex].id))
                                }}
                                ref={imageGalleryRef}
                                onClick={onImageClick}
                                useBrowserFullscreen={false}
                            />
                            <button
                                className='mobile-button-styler'
                                onClick={() => {
                                    dispatch(setCurrentCollectionId(col.id))
                                    dispatch(setToggleEditPhoto(true))
                                }}
                            >Edit Photo</button>
                        </div>
                        <p>{col.description}</p>
                        <div 
                            className='admin-add-buttons'
                            onClick={() => {
                                dispatch(setToggleAddPhoto(true))
                                dispatch(setCurrentCollectionId(col.id))
                              }}
                        >
                            <FaPlus 
                              
                            />
                        </div>
                        {/* <h6>.</h6> */}
                    </div>
                )
            })
        ) : ''}
    </div>
  )
}

export default Admin