import React, { useEffect, useState } from 'react'
import './Login.css'
import { selectLoginError, selectToggleLoginError, setUserData } from '../../reducers/userSlice'
import { useSelector } from 'react-redux'

const Login = ({
    handleNavigate,
    handleLogin
}) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const toggleLoginError = useSelector(selectToggleLoginError)
    const loginError = useSelector(selectLoginError)

    useEffect(() => {
        const user = localStorage.getItem('user')
        if (user) {
            handleNavigate('/admin')
            setUserData(user)
        }
    },[handleNavigate])

  return (
    <div className='login'>
        <form
            onSubmit={(e) => {
                e.preventDefault()
                handleLogin(email, password)

            }}
        >
            <label htmlFor='email'>Email:</label>
            <input 
                type='email'
                id='email'
                placeholder='Enter Email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />

            <label htmlFor='password'>Password:</label>
            <input 
                type='password'
                id='password'
                placeholder='Enter Password'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                />
            {toggleLoginError ? (
                <div className='login-error-message'>
                    <p>Login error: </p>
                    {loginError.errorCode === 'auth/user-not-found' 
                    ? <p> User Not Found</p> 
                    : loginError.errorCode === 'auth/wrong-password' 
                    ? <p> Incorrect Password</p> 
                    : ''}
                </div>
            ) : ''}
            <input 
                type='submit'
                value='Login'
            />
        </form>
    </div>
  )
}

export default Login