import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    currentCollectionId: '',
    toggleCollectionDropdown: false,
    toggleAddCollection: false,
    toggleEditCollection: false,
    toggleDeleteCollection: false,
}

const collectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {
        setCurrentCollectionId: (state, action) => {
            state.currentCollectionId = action.payload
        },
        setToggleCollectionDropdown: (state, action) => {
            state.toggleCollectionDropdown = action.payload
        },
        setToggleAddCollection: (state, action) => {
            state.toggleAddCollection = action.payload
        },
        setToggleEditCollection: (state, action) => {
            state.toggleEditCollection = action.payload
        },
        setToggleDeleteCollection: (state, action) => {
            state.toggleDeleteCollection = action.payload
        }
    }
})

export const {
    setCurrentCollectionId,
    setToggleCollectionDropdown,
    setToggleAddCollection,
    setToggleEditCollection,
    setToggleDeleteCollection
} = collectionSlice.actions

export const selectCurrentCollectionId = (state) => state.collection.currentCollectionId
export const selectToggleCollectionDropdown = (state) => state.collection.toggleCollectionDropdown
export const selectToggleAddCollection = (state) => state.collection.toggleAddCollection
export const selectToggleEditCollection = (state) => state.collection.toggleEditCollection
export const selectToggleDeleteCollection = (state) => state.collection.toggleDeleteCollection

export default collectionSlice.reducer