//====================BOILERPLATE IMPORTS====================//
import React, { useEffect } from 'react';
import './App.css';

//====================COMPONENT IMPORTS====================//
import Header from './features/Header/Header';
import Footer from './features/Footer/Footer';
import Login from './features/Login/Login';
import Home from './features/Home/Home';
import Admin from './features/Admin/Admin';
import AddPhoto from './features/AddPhoto/AddPhoto';
import AddCollection from './features/AddCollection/AddCollection';
import EditCollection from './features/EditCollection/EditCollection';

//====================PACKAGE IMPORTS====================//
import { 
  Route, 
  Routes, 
  useNavigate 
} from 'react-router-dom';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';

//====================FIREBASE IMPORTS====================//
import { 
  collection, 
  deleteDoc, 
  doc, 
  getDoc, 
  onSnapshot, 
  query, 
  serverTimestamp, 
  setDoc, 
  updateDoc 
} from "firebase/firestore";
import { 
  getDownloadURL, 
  ref, 
  uploadBytesResumable 
} from 'firebase/storage';
import { 
  signInWithEmailAndPassword, 
  signOut 
} from 'firebase/auth';
import { 
  auth, 
  db, 
  storage 
} from './firebaseConfig';

//====================REDUCERS IMPORTS====================//
import { 
  selectData, 
  setData 
} from './reducers/appSlice';
import { 
  setIsLoggedIn, 
  setLoginError, 
  setToggleLoginError, 
  setUserData 
} from './reducers/userSlice';
import { 
  selectCurrentPhotoId,
  selectToggleAddPhoto, 
  selectToggleEditPhoto, 
  setToggleAddPhoto, 
  setToggleDeletePhoto, 
  setToggleEditPhoto, 
  setUploadProgess 
} from './reducers/photoSlice';
import { 
  selectCurrentCollectionId, 
  selectToggleAddCollection, 
  selectToggleDeleteCollection, 
  selectToggleEditCollection, 
  setToggleAddCollection, 
  setToggleDeleteCollection, 
  setToggleEditCollection 
} from './reducers/collectionSlice';
import DeleteColletion from './features/DeleteCollection/DeleteColletion';
import EditPhoto from './features/EditPhoto/EditPhoto';


function App() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector(selectData)
  const toggleAddPhoto = useSelector(selectToggleAddPhoto)
  const currentCollectionId = useSelector(selectCurrentCollectionId)
  const toggleAddCollection = useSelector(selectToggleAddCollection)
  const toggleEditCollection = useSelector(selectToggleEditCollection)
  const toggleDeleteCollection = useSelector(selectToggleDeleteCollection)
  const toggleEditPhoto = useSelector(selectToggleEditPhoto)
  const currentPhotoId = useSelector(selectCurrentPhotoId)
  // console.log(data)

  useEffect(() => {
    const userData = localStorage.getItem('user')
    if (userData) {
      dispatch(setUserData(userData))
      dispatch(setIsLoggedIn(true))
    }
    const fetchData = async () => {

      const q = query(collection(db, "collections"))
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const dataArr = [];
        querySnapshot.forEach((doc) => {
            dataArr.push(doc.data());
        });
        dispatch(setData(dataArr))
      });
      return unsubscribe

    }

    fetchData()
  },[dispatch])

  // useEffect(() => {
  // const updateData = async () => {
  //   const newDocRef = doc(db, 'collections', '12345')
  //   const newDocSnap = await getDoc(newDocRef)

  //   const newDocRef2 = doc(db, 'collections', 'UvKcC3K1SFCHo9A1daye')

  //   if (newDocSnap.exists()) {
  //     console.log(newDocSnap.data())
  //     const tempArr = newDocSnap.data().photos
  //     console.log(tempArr)

  //     await updateDoc(newDocRef2, {
  //       'photos': arrayUnion(tempArr[0])
  //     })
  //   }
  
  // }
  // updateData()

  // },[data])

 
  //====================NAVIGATION HANDLER====================//
  const handleNavigate = (page) => {
    navigate(page)
  }
  //====================HANDLE LOGIN====================//
  const handleLogin = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        localStorage.setItem('user', JSON.stringify(user))
        setUserData(user)
        handleNavigate('/admin')
        dispatch(setIsLoggedIn(true))
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message 
        dispatch(setToggleLoginError(true))
        dispatch(setLoginError({
          errorCode: errorCode,
          errorMessage: errorMessage
        }))
        setTimeout(() => {
          dispatch(setToggleLoginError(false))
          dispatch(setLoginError({}))
        }, 3000)
      })
  }

  //====================HANDLE SIGN OUT====================//
  const handleSignOut = async () => {
    signOut(auth)
    .then(() => {
      localStorage.removeItem('user')
      dispatch(setUserData({}))
      dispatch(setIsLoggedIn(false))
      handleNavigate('/')
    })
    .then(() => {
      window.location.reload()
    })
  }
  
  //====================HANDLE IMAGE UPLOAD====================//
  const handleUploadImage = async (imageUpload, photoData) => {
    if (imageUpload === null) return

    const imageRef = ref(storage, `${currentCollectionId}/${photoData.id}.JPEG`)

    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        dispatch(setUploadProgess(progress))
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

          if (data) {
          
            const tempData = JSON.parse(JSON.stringify(data))

            await Promise.all(tempData.map(async (col) => {
              if (col.id === currentCollectionId) {
                col.photos = [...col.photos, {
                  ...photoData, 
                  url: downloadURL, 
                  original: downloadURL,
                  originalWidth: '100%',
                  originalClass: 'image-gallery-photo'
                }]
                try {
                  const docRef = doc(db, 'collections', String(currentCollectionId))
                  await updateDoc(docRef, col)
                  console.log('photo added to db')
                  // handleMessage('Photo Uploaded!')
                } catch (e) {
                  console.log(e)
                }
              }
            }))
            .then(() => {
              dispatch(setToggleAddPhoto(false))
              dispatch(setUploadProgess(0))
            })
        
          }

        });
      }
      )  
  }

  //====================HANDLE ADD COLLECTION====================//
  const handleAddCollection = async (collectionData) => {

    const docRef = doc(db, 'collections', collectionData.id)

    await setDoc(docRef, {...collectionData, date_created: serverTimestamp()})

    dispatch(setToggleAddCollection(false))

  }

  //====================HANDLE EDIT COLLECTION====================//
  const handleEditCollection = async (collectionData) => {
    const docRef = doc(db, 'collections', String(currentCollectionId))
    
    await updateDoc(docRef, {
      'name': collectionData.name,
      'description': collectionData.description
    })

    dispatch(setToggleEditCollection(false))
  }
  //====================HANDLE DELETE COLLECTION====================//
  const handleDeleteCollection = async () => {
    const docRef = doc(db, 'collections', String(currentCollectionId))
    const docRef2 = doc(db, '__deleted_collections', String(currentCollectionId))
    
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()) {
      await setDoc(docRef2, docSnap.data())
      await deleteDoc(docRef)

      console.log('doc deleted')
      dispatch(setToggleDeleteCollection(false))
    }
  }

  const handleDeletePhoto = async () => {
    const tempData = JSON.parse(JSON.stringify(data))
    
    let tempArr = []

    tempData.forEach(col => {
      if (col.id === currentCollectionId) {
        col.photos.forEach(photo => {
          if (photo.id === currentPhotoId) {
            const index = col.photos.indexOf(photo)
            
            col.photos.splice(index, 1)
            tempArr = col.photos
          }
        })
      }
      
    })
    
    const docRef = doc(db, 'collections', String(currentCollectionId))

    await updateDoc(docRef, {
      'photos': tempArr
    })

    dispatch(setToggleDeletePhoto(false))
    dispatch(setToggleEditPhoto(false))
  }

    return (
    <div className="App">
      <Header 
        handleNavigate={handleNavigate}
      />
      <Routes>
        <Route path='/' element={
          <Home 
            handleNavigate={handleNavigate}
          />
        }></Route>
        <Route path='/login' element={
          <Login 
            handleNavigate={handleNavigate} 
            handleLogin={handleLogin} 
          />
        }></Route>
        <Route path='/admin' element={
          <Admin 
            handleNavigate={handleNavigate}
          />
        }></Route>
      </Routes>
      <Footer 
        handleNavigate={handleNavigate}
        handleSignOut={handleSignOut}
      />
      {toggleAddPhoto ? (
        <div>
          <div
            className={`add-photo-container ${toggleAddPhoto ? 'active' : ''}`}
            onClick={() => dispatch(setToggleAddPhoto(false))}
            ></div>
          <AddPhoto 
            handleUploadImage={handleUploadImage}
            />
        </div>
      ) : ''}
      {toggleAddCollection ? (
        <div>
          <div
            className={`add-collection-container ${toggleAddCollection ? 'active' : ''}`}
            onClick={() => dispatch(setToggleAddCollection(false))}    
          >
          </div>
          <AddCollection 
            handleAddCollection={handleAddCollection}
          />
        </div>
      ) : ''}
      {toggleEditCollection ? (
        <div>
          <div
            className={`edit-collection-container ${toggleEditCollection ? 'active' : ''}`}
            onClick={() => dispatch(setToggleEditCollection(false))}    
          >
          </div>
          <EditCollection 
            handleEditCollection={handleEditCollection}
          />
        </div>
      ) : ''}
      {toggleDeleteCollection ? (
        <div>
          <div
            className={`delete-collection-container ${toggleDeleteCollection ? 'active' : ''}`}
            onClick={() => dispatch(setToggleDeleteCollection(false))}    
          >
          </div>
          <DeleteColletion 
            handleDeleteCollection={handleDeleteCollection}
          />
        </div>
      ) : ''}
      {toggleEditPhoto ? (
        <div>
          <div
            className={`edit-photo-container ${toggleEditPhoto ? 'active' : ''}`}
            onClick={() => dispatch(setToggleEditPhoto(false))}    
          >
          </div>
          <EditPhoto 
            handleUploadImage={handleUploadImage}
            handleDeletePhoto={handleDeletePhoto}
          />
        </div>
      ) : ''}
    </div>
  );
}

export default App;
