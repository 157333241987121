import React, { useEffect, useState } from 'react'
import './EditCollection.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentCollectionId, setToggleDeleteCollection, setToggleEditCollection } from '../../reducers/collectionSlice'
import { selectData } from '../../reducers/appSlice'

const EditCollection = ({
    handleEditCollection
}) => {

    const [collectionName, setCollectionName] = useState('')
    const [collectionDescription, setCollectionDescription] = useState('')

    const data = useSelector(selectData)
    const currentCollectionId = useSelector(selectCurrentCollectionId)

    const dispatch = useDispatch()

    useEffect(() => {
        if (data && data.length > 0) {
            data.forEach(col => {
                if (col.id === currentCollectionId) {
                    setCollectionName(col.name)
                    setCollectionDescription(col.description)
                }
            })
        }
    },[currentCollectionId, data])

  return (
    <div className='edit-collection'>
        <form onSubmit={(e) => {
            e.preventDefault()
            handleEditCollection({
                name: collectionName,
                description: collectionDescription,
            })
        }}>
            <label htmlFor='collection-name'>Collection Name:</label>
            <input 
                type='text'
                name='collection-name'
                id='collection-name'
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                required
            />
            <label htmlFor='collection-description'>Description</label>
            <textarea 
                type='textarea'
                rows='10'
                name='collection-description'
                id='collection-description'
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
            />

            <input 
                type='submit'
                value='Submit'
            />
        </form>
        <button
            className='mobile-button-delete-styler'
            onClick={() => {
                dispatch(setToggleEditCollection(false))
                dispatch(setToggleDeleteCollection(true))
            }}
        >Delete Collection</button>
    </div>
  )
}

export default EditCollection