import React from 'react'
import './Home.css'
import Collections from '../Collections/Collections'

const Home = () => {
  return (
    <div className='home'>
        <Collections />
    </div>
  )
}

export default Home