import React from 'react'
import './Footer.css'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../reducers/userSlice'

const Footer = ({
    handleNavigate,
    handleSignOut
}) => {

  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <footer>
      {isLoggedIn ? (
        <div>
          <button
            onClick={() => handleSignOut()}
          >Sign Out</button>
          <button
            onClick={() => handleNavigate('/admin')}
          >Admin</button>
        </div>
      ) : (
        <button
            onClick={() => handleNavigate('/login')}
            style={{
              border: '0px !important',
            }}
        >Manage</button>
        
      )}
    </footer>
  )
}

export default Footer