import React from 'react'
import './DeleteCollection.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectToggleDeleteCollection, setToggleDeleteCollection, setToggleEditCollection } from '../../reducers/collectionSlice'

const DeleteColletion = ({
    handleDeleteCollection,
}) => {

    const toggleDeleteCollection = useSelector(selectToggleDeleteCollection)

    const dispatch = useDispatch()

  return (
    <div className={`delete-collection ${toggleDeleteCollection ? 'active' : ''}`}>
        <h4>Are you sure you want to delete this collection?</h4>
        <h4>This is <strong>irreversible</strong></h4>
        <button
            className='mobile-button-delete-styler'
            onClick={() => {
                handleDeleteCollection()
            }}
        >Yes, delete</button>
        <button
            className='mobile-button-styler'
            onClick={() => {
                dispatch(setToggleDeleteCollection(false))
                dispatch(setToggleEditCollection(true))
            }}
        >No, take me back</button>
    </div>
  )
}

export default DeleteColletion