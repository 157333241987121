import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    toggleAddPhoto: false,
    uploadProgress: 0,
    addPhotoInputError: false,
    toggleEditPhoto: false,
    currentPhotoId : '', 
    toggleDeletePhoto: false,
}  

const photoSlice = createSlice({
    name: 'photo',
    initialState,
    reducers: {
        setToggleAddPhoto: (state, action) => {
            state.toggleAddPhoto = action.payload
        },
        setUploadProgess: (state, action) => {
            state.uploadProgress = action.payload
        },
        setAddPhotoInputError: (state, action) => {
            state.addPhotoInputError = action.payload
        },
        setToggleEditPhoto: (state, action) => {
            state.toggleEditPhoto = action.payload
        }, setCurrentPhotoId: (state, action) => {
            state.currentPhotoId = action.payload
        },
        setToggleDeletePhoto: (state, action) => {
            state.toggleDeletePhoto = action.payload
        }
    }
})

export const {
    setToggleAddPhoto,
    setUploadProgess,
    setAddPhotoInputError,
    setToggleEditPhoto,
    setCurrentPhotoId,
    setToggleDeletePhoto
} = photoSlice.actions

export const selectToggleAddPhoto = (state) => state.photo.toggleAddPhoto
export const selectUploadProgress = (state) => state.photo.uploadProgress
export const selectAddPhotoInputError = (state) => state.photo.addPhotoInputError
export const selectToggleEditPhoto = (state) => state.photo.toggleEditPhoto
export const selectCurrentPhotoId = (state) => state.photo.currentPhotoId
export const selectToggleDeletePhoto = (state) => state.photo.toggleDeletePhoto


export default photoSlice.reducer