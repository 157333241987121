import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../reducers/appSlice'
import userReducer from '../reducers/userSlice'
import photoReducer from '../reducers/photoSlice'
import collectionReducer from '../reducers/collectionSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    photo: photoReducer,
    collection: collectionReducer,
  },
});
