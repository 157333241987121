// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyADugWvfpfh7NSLLqKMpbzvnE7PtBep9MQ",
  authDomain: "photo-portfolio-84e34.firebaseapp.com",
  projectId: "photo-portfolio-84e34",
  storageBucket: "photo-portfolio-84e34.appspot.com",
  messagingSenderId: "1063989153242",
  appId: "1:1063989153242:web:8d7b099bfa36bf874c04e3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore(app);
const storage = getStorage(app)

export { auth, db, storage }